import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";

import { selectors as MQTT_CONNECTION_SELECTORS } from "../ducks/mqtt";
import { actions as NODE_ACTIONS } from "../ducks/node";

import { errorNotification } from "../lib/utils/notification";

import store from "../store";

const translations = defineMessages({
  wrongCloudNodeName: {
    id: "general.wrongCloudNodeName",
    defaultMessage: "Node does not exist in Quickstream Cloud",
  },
});

function useSetNodeConnection() {
  const { cwid } = useParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const nodeList = useSelector(MQTT_CONNECTION_SELECTORS.getNodes);
  const nodeData = nodeList.find((node) => node.cwid === cwid);

  useEffect(() => {
    if (nodeData?.cwid) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  if (nodeList.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);
    navigate("/");
  }
}

export default useSetNodeConnection;
