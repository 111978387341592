import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  ENGINE_TYPES,
  INPUT_URL_TYPE,
  INPUT_WEBCAM_TYPE,
  INPUT_TYPES,
  INPUT_DECKLINK_TYPE,
  QUICKSTREAM_METHOD_V2,
} from "../lib/utils/constants";

import { selectors as NODE_CHANNEL_SELECTORS } from "../ducks/nodeChannels";

function useNodeChannelConfig() {
  const { id: channelId } = useParams();
  const channelConfig = useSelector((state) => NODE_CHANNEL_SELECTORS.getNodeChannelConfig(state, channelId));

  const isInput = channelConfig?.mainInput || channelConfig?.liveSource?.mainInput;
  const isBackup = channelConfig?.backupInput;

  const isMainQSMethod = !!(channelConfig?.mainInput?.inputStreamCfg?.type === QUICKSTREAM_METHOD_V2.inQSDirect.value);

  const mainInputConfig = channelConfig?.mainInput || channelConfig?.liveSource?.mainInput;
  const backupInputConfig = channelConfig?.backupInput || channelConfig?.liveSource?.backupInput;
  const isMainInputURLType = mainInputConfig?.type === INPUT_URL_TYPE.value;

  const isMainInputWebcamType = channelConfig?.mainInput?.type === INPUT_WEBCAM_TYPE.value;
  const isInputRTMPTypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputRtmp.value;
  const isMainInputDecklinkType = channelConfig?.mainInput?.type === INPUT_DECKLINK_TYPE.value;
  const isInputRTPTypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isInputNDITypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputNdi.value;

  const isBackupInputURLType = channelConfig?.backupInput?.type === INPUT_URL_TYPE.value;
  const isBackupInputWebcamType = channelConfig?.backupInput?.type === INPUT_WEBCAM_TYPE.value;
  const isBackupInputRTMPTypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputRtmp.value;
  const isBackupInputDecklinkType = channelConfig?.backupInput?.type === INPUT_DECKLINK_TYPE.value;
  const isBackupInputRTPTypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isBackupInputNDITypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputNdi.value;

  const isDirectInput = !!channelConfig?.input;
  const isEngineDirect = channelConfig?.type === ENGINE_TYPES.DIRECT;
  const isEngineTranscoding = channelConfig?.type === ENGINE_TYPES.TRANSCODING;
  const isEnginePlayout = channelConfig?.type === ENGINE_TYPES.PLAYOUT;

  const outputs = channelConfig?.outputs || [];

  return {
    isInput,
    isBackup,
    isMainQSMethod,
    isMainInputURLType,
    isMainInputWebcamType,
    isInputRTMPTypeSelected,
    isMainInputDecklinkType,
    isInputRTPTypeSelected,
    isInputNDITypeSelected,
    isBackupInputURLType,
    isBackupInputWebcamType,
    isBackupInputRTMPTypeSelected,
    isBackupInputDecklinkType,
    isBackupInputRTPTypeSelected,
    isBackupInputNDITypeSelected,
    channelConfig,
    isDirectInput,
    isEngineDirect,
    isEngineTranscoding,
    isEnginePlayout,
    mainInputConfig,
    backupInputConfig,
    outputs,
  };
}

export default useNodeChannelConfig;
