import React, { useState } from "react";
import { Col, Card, Row, Modal, Button, Empty } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined, CaretDownOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { useSelector } from "react-redux";

import { PlatformAPI } from "../../lib/api";
import MQTTService from "../../services/mqtt";

import InputTextFiled from "../Fields/InputTextField";

import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import parseConnectionMethodName from "../../lib/utils/parseConnectionMethodName";
import themeColor from "../../lib/style/theme";
import { OUTPUTS_TYPE, NEW, MESSAGE_TYPE, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import { DEFAULT_TRANSCODED_DESTINATION_URL } from "../../lib/utils/defaultValues/defaultTranscodedOutput";
import { warningNotification, errorNotification } from "../../lib/utils/notification";

import { selectors as NODE_SELECTORS } from "../../ducks/node";

import StreamSourceElement from "../StreamSourceElement";
import OutputFormItem from "../NodeChannelFormV5/OutputFormItem";
// eslint-disable-next-line max-len
import EngineTranscodingMuxer from "../NodeChannelFormV5/EngineTranscoding/EngineTranscodingSection/EngineTranscodingMuxer";

const translations = defineMessages({
  fillAllRequiredField: {
    id: "OutputURLFormItem.fillAllRequiredField",
    defaultMessage: "Please fill all required field",
  },
  fieldNameValidation: {
    id: "OutputURLFormItem.fieldNameValidation",
    defaultMessage: "Output name validation error - please fix it before add new output",
  },
  nameAlreadyExist: {
    id: "OutputURLFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

function TranscodingOutputURLFormItem({
  form,
  prefix,
  muxedOutput,
  isNewOutput,
  handleFinish,
  channelId,
  nodeChannels,
  detailView,
}) {
  const { formatMessage } = useIntl();
  const { validateFields, getFieldValue, setFieldsValue, resetFields } = form;
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);

  const [outputSectionVisible, setOutputSectionVisible] = useState(null);

  const [disableCondition, setDisableCondition] = useState(false);
  const numberOfOutputs = muxedOutput && muxedOutput.urlDestinations ? muxedOutput.urlDestinations.length : 0;

  const [muxerData, setMuxerData] = useState(null);

  const handleUrlDestination = async () => {
    try {
      if (!isNewOutput) {
        resetFields();
      }
      await validateFields([["outputs", prefix[1], "name"]]);
      await validateFields();

      const outputs = getFieldValue(["outputs"]);

      const urlDestinations = getFieldValue([...prefix, "urlDestinations"]) || [];
      const outputName = getFieldValue([...prefix, "name"]);

      outputs[prefix[1]].urlDestinations[urlDestinations.length] = {
        ...DEFAULT_TRANSCODED_DESTINATION_URL,
        outputName: `Destination-${urlDestinations.length} ${outputName}`,
      };

      setFieldsValue({
        outputs,
      });

      setOutputSectionVisible(NEW);
    } catch (error) {
      warningNotification(formatMessage)(translations.fillAllRequiredField);
    }
  };

  const handleOKOutput = async () => {
    if (!disableCondition) {
      try {
        await validateFields();

        if (isNewOutput) {
          isNewOutput(prefix[1]);
        }

        const updatedFormData = getFieldValue();

        setOutputSectionVisible(null);
        handleFinish(updatedFormData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error - formValidation", error);
      }
    }
  };

  const handleCancelTranscodedOutput = (isNewDestination) => {
    if (!isNewDestination) {
      setOutputSectionVisible(null);

      return;
    }

    const outputs = getFieldValue(["outputs"]);
    const outputIndex = prefix[1];
    const parsedOutputs = outputs.map((output, index) => {
      if (index === outputIndex) {
        const parsedOutputDestinations = output.urlDestinations.slice(0, -1);

        return {
          ...output,
          urlDestinations: parsedOutputDestinations,
        };
      }

      return output;
    });

    setFieldsValue({ outputs: parsedOutputs });
    setOutputSectionVisible(null);
  };

  const handleRemoveDestination = async (outputName) => {
    const channelConfig = getFieldValue();
    const outputToRemove = muxedOutput?.urlDestinations.find((output) => output.outputName === outputName);
    const isQSMethodOutput = outputToRemove.type === QUICKSTREAM_METHOD_V2.outQSDirect.value;

    const parsedFormMuxedOutputOutputs = muxedOutput.urlDestinations.filter(
      (output) => output.outputName !== outputName
    );

    const outputs = getFieldValue(["outputs"]);
    outputs[prefix[1]].urlDestinations = parsedFormMuxedOutputOutputs;
    const updatedFormData = { ...channelConfig, outputs };

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
        channelId,
        channelConfig: JSON.stringify(updatedFormData),
      },
    });

    setFieldsValue({
      outputs,
    });

    if (isQSMethodOutput) {
      await PlatformAPI.removeFingerprintFromChannel({ oldCloudIds: [outputToRemove?.cloudId] }, { errorNotification });
    }
    setOutputSectionVisible(null);
  };

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== muxedOutput?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  const isUrlDestinations = muxedOutput?.urlDestinations && muxedOutput?.urlDestinations.length > 0;

  return (
    <>
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="TranscodingOutputURLFormItem.muxer" defaultMessage="Muxer" />}>
          <EngineTranscodingMuxer
            disabled={!!detailView}
            prefix={prefix}
            setMuxerData={setMuxerData}
            muxerData={muxerData}
          />
        </Card>
      </Col>
      <StyledColCenter span={24}>
        <CaretDownOutlined />
      </StyledColCenter>
      <Col span={24}>
        <Card title={<FormattedMessage id="MuxedOutput.destinations" defaultMessage="Destinations" />}>
          {!isUrlDestinations && detailView && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {muxedOutput?.urlDestinations &&
            muxedOutput?.urlDestinations.map((output, index) => {
              const handleClick = () => {
                validateFields();
                resetFields();
                setOutputSectionVisible(output.outputId);
              };

              return (
                <Row gutter={24} key={output.outputId}>
                  <Col span={24}>
                    <StreamSourceElement
                      connectionType={output?.type}
                      destinationAddress={output?.destinationAddress}
                      destinationPort={output?.destinationPort}
                      localPort={output?.localPort}
                      handleClick={handleClick}
                      interfaceIp={output?.interfaceIp}
                      label={parseConnectionMethodName(output?.type)}
                      title={output?.outputName ? output?.outputName : parseConnectionMethodName(output?.type)}
                      cloudId={output?.cloudId}
                    />
                    {outputSectionVisible === output.outputId && (
                      <StyledModal
                        zIndex={100}
                        centered
                        title={
                          <FormattedMessage
                            id="TranscodingOutputURLFormItem.editMuxedOutput"
                            defaultMessage="Edit Muxed Output"
                          />
                        }
                        open={outputSectionVisible === output.outputId}
                        onOk={handleOKOutput}
                        onCancel={() => handleCancelTranscodedOutput()}
                        forceRender
                        footer={detailView ? null : undefined}
                        okText={
                          <span>
                            <FormattedMessage id="general.save" defaultMessage="Save" />
                          </span>
                        }
                        okButtonProps={{ icon: <SaveOutlined /> }}
                      >
                        <OutputFormItem
                          channelId={channelId}
                          disabled={!!detailView}
                          handleRemove={handleRemoveDestination}
                          index={index}
                          initialValue={output}
                          name={output?.outputName}
                          outputType={OUTPUTS_TYPE.TRANSCODING}
                          prefix={[...prefix, "urlDestinations"]}
                          setDisableCondition={setDisableCondition}
                          form={form}
                        />
                      </StyledModal>
                    )}
                  </Col>
                </Row>
              );
            })}
          {!detailView && (
            <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleUrlDestination}>
              <span>
                <FormattedMessage id="TranscodingOutputURLFormItem.addDestination" defaultMessage="Add destination" />
              </span>
            </StyledAddButton>
          )}
          {outputSectionVisible === NEW && (
            <StyledModal
              zIndex={100}
              centered
              title={
                <FormattedMessage id="TranscodingOutputURLFormItem.newDestinations" defaultMessage="New destination" />
              }
              open={outputSectionVisible === NEW}
              onOk={handleOKOutput}
              onCancel={() => handleCancelTranscodedOutput(NEW)}
            >
              <OutputFormItem
                channelId={channelId}
                disabled={!!detailView}
                handleRemove={handleRemoveDestination}
                index={numberOfOutputs}
                initialValue={DEFAULT_TRANSCODED_DESTINATION_URL}
                outputType={OUTPUTS_TYPE.TRANSCODING}
                prefix={[...prefix, "urlDestinations"]}
                setDisableCondition={setDisableCondition}
                form={form}
              />
            </StyledModal>
          )}
        </Card>
      </Col>
    </>
  );
}

const StyledColCenter = styled(Col)`
  display: flex;
  justify-content: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

TranscodingOutputURLFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  channelId: PropTypes.string,
  isNewOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  muxedOutput: PropTypes.object,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
};

TranscodingOutputURLFormItem.defaultProps = {
  detailView: null,
  channelId: null,
  muxedOutput: null,
};

export default TranscodingOutputURLFormItem;
