import React, { useEffect, createContext } from "react";
import { Card, Form, Row, Col, Spin, Drawer } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CaretDownOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import store from "../../store";

import themeColor from "../../lib/style/theme";
import {
  FORM_VERSION_V5,
  INPUT_URL_TYPE,
  MESSAGE_TYPE,
  ENGINE_TYPES,
  INPUT_TYPES,
  QUICKSTREAM_METHOD_V2,
  CONNECTION_TYPE,
} from "../../lib/utils/constants";

import { selectors as CHANNEL_SELECTORS, actions as CHANNEL_ACTIONS } from "../../ducks/nodeChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../ducks/loadingData";

import CloudChannelsServices from "../../services/cloudChannels";
import NodeService from "../../services/node";
import MQTTService from "../../services/mqtt";

import InputSection from "./EngineDirect/InputSection";
import InputTranscodingSection from "./EngineTranscoding/InputTranscodingSection";
import GeneralSection from "./GeneralSection";
import EngineDirectOutputs from "./EngineDirect/EngineDirectOutputs";
import EngineTranscodingSection from "./EngineTranscoding/EngineTranscodingSection";
import DetailSection from "./DetailSection";
import DirectOutputTitle from "./EngineDirect/DirectOutputTitle";
import NodePorts from "../../pages/Node/NodePorts";
import AudioStreamCount from "./EngineTranscoding/AudioStreamCount";
import GlobalCodecSection from "./EngineTranscoding/GlobalCodecSection";
import BackButton from "../BackButton";

import GlobalErrorBoundaries from "../ErrorBoundaries/GlobalErrorBoundaries";

import useNodeDevices from "../../hooks/useNodeDevices";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import setDifference from "../../lib/utils/setDifference";

export const FormV5Context = createContext();

function NodeChannelFormV5({ channelConfig, id, editMode, detailView }) {
  const [form] = Form.useForm();
  const { cwid } = useParams();

  const fingerprint = useSelector(NODE_SELECTORS.getFingerprint);
  const shortFingerprint = useSelector(NODE_SELECTORS.getShortFingerprint);
  const cnn = useSelector(NODE_SELECTORS.getCnn);
  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);
  const sharedChannelList = useSelector(CLOUD_CHANNELS_SELECTORS.getSharedChannels);
  const stundAddress = useSelector(NODE_SELECTORS.getStundAddress);
  const openDrawer = useSelector(NODE_SELECTORS.getNodeOpenPortsDrawer);

  const { formatMessage } = useIntl();
  useNodeDevices();

  const isDirectInput = !!channelConfig?.input;

  const {
    status: { requestedStatusText, playingSource, inputs: inputsStatus },
  } = nodeChannels.find((channel) => channel.channelId === id) || {
    status: {},
  };

  useEffect(() => {
    if (!detailView) {
      if (!fingerprint) {
        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.FINGERPRINT,
          },
        });

        store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.FINGERPRINT));
      }
      if (!shortFingerprint) {
        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.SHORT_FINGERPRINT,
          },
        });

        store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.SHORT_FINGERPRINT));
      }
      if (!cnn) {
        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.GET_HOST_NAME,
          },
        });

        store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.GET_HOST_NAME));
      }
    }
  }, [detailView, fingerprint, cnn, shortFingerprint]);

  useEffect(() => {
    if (!detailView) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
      CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [detailView, formatMessage]);

  const handleFinish = async (formData) => {
    const isEngineTranscoding = formData?.type === ENGINE_TYPES.TRANSCODING;

    const newInputPermissionId = isEngineTranscoding
      ? formData?.mainInput?.inputStreamCfg?.permissionId
      : formData?.input?.mainConnection?.permissionId;

    const oldPermissionId = isEngineTranscoding
      ? channelConfig?.mainInput?.inputStreamCfg?.permissionId
      : channelConfig?.input?.mainConnection?.permissionId;

    const inputConnectionMethod = isEngineTranscoding
      ? formData?.mainInput?.inputStreamCfg?.type
      : formData?.input?.mainConnection?.type;
    const isInputQuickstreamMethod = inputConnectionMethod === QUICKSTREAM_METHOD_V2.inQSDirect.value;
    const newCloudIdsToConnect = new Set();

    if (formData?.outputs) {
      const oldCloudIds = new Set();
      const newCloudIds = new Set();

      if (channelConfig.outputs) {
        channelConfig.outputs.forEach((output) => {
          if (output.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            oldCloudIds.add(output.cloudId);
          }

          if (output?.urlDestinations) {
            output.urlDestinations.forEach((outputDestination) => {
              const oldCloudId = outputDestination?.cloudId;
              if (oldCloudId) {
                oldCloudIds.add(outputDestination?.cloudId);
              }
            });
          }
        });
      }

      if (formData.outputs) {
        formData.outputs.forEach((output) => {
          if (output.type === QUICKSTREAM_METHOD_V2.outQSDirect.value) {
            newCloudIds.add(output.cloudId);
          }

          if (output?.urlDestinations) {
            output?.urlDestinations.forEach((outputDestination) => {
              const newCloudId = outputDestination?.cloudId;
              if (newCloudId) {
                newCloudIds.add(outputDestination?.cloudId);
              }
            });
          }
        });
      }

      // check Set() - if there is any new cloudIds to connect - if so - connect to cloud
      const cloudIdsToConnect = setDifference(newCloudIds, oldCloudIds);
      cloudIdsToConnect.forEach((cloudId) => newCloudIdsToConnect.add(cloudId));

      if (newCloudIdsToConnect.size > 0) {
        const cloudData = {
          cnn,
          cwid,
          cloudIds: Array.from(newCloudIdsToConnect),
          type: CONNECTION_TYPE.NODE_CLOUD,
        };

        await NodeService.connectFingerprintToChannel(cloudData, {
          errorNotification: errorNotification(formatMessage),
        });
      }
    }

    const newPermissionIdToConnect =
      isInputQuickstreamMethod && oldPermissionId !== newInputPermissionId && isEngineTranscoding
        ? formData?.mainInput?.inputStreamCfg?.permissionId
        : formData?.input?.mainConnection?.permissionId;

    if (newPermissionIdToConnect) {
      const channelData = {
        cnn,
        cwid,
        permissionId: isEngineTranscoding
          ? formData?.mainInput?.inputStreamCfg?.permissionId
          : formData?.input?.mainConnection?.permissionId,
        type: CONNECTION_TYPE.NODE_DIRECT,
      };

      await NodeService.connectFingerprintToPermission(channelData, {
        errorNotification: errorNotification(formatMessage),
      });
    }

    if (editMode) {
      const oldCloudIdsToRemove = new Set();
      const oldPermissionIdToRemove = oldPermissionId !== newInputPermissionId && oldPermissionId;

      if (oldPermissionId !== newInputPermissionId) {
        await NodeService.updateChannelData(
          { oldPermissionId },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
          }
        );
      }

      if (channelConfig.outputs) {
        const oldCloudIds = new Set();
        const newCloudIds = new Set();

        channelConfig.outputs.forEach((output) => {
          const oldCloudId = output?.cloudId;
          if (oldCloudId) {
            oldCloudIds.add(output?.cloudId);
          }

          if (output?.urlDestinations) {
            output.urlDestinations.forEach((outputDestination) => {
              if (outputDestination?.cloudId) {
                oldCloudIds.add(outputDestination?.cloudId);
              }
            });
          }
        });

        formData.outputs.forEach((output) => {
          const newCloudId = output?.cloudId;
          if (newCloudId) {
            newCloudIds.add(output?.cloudId);
          }

          if (output?.urlDestinations) {
            output?.urlDestinations.forEach((outputDestination) => {
              if (outputDestination?.cloudId) {
                newCloudIds.add(outputDestination?.cloudId);
              }
            });
          }
        });

        // check Set() - if there is any oldCloudId to remove - if so - remove it
        const cloudIdsToRemove = setDifference(oldCloudIds, newCloudIds);
        cloudIdsToRemove.forEach((cloudId) => oldCloudIdsToRemove.add(cloudId));

        if (oldCloudIdsToRemove.size > 0) {
          await NodeService.updateChannelData(
            { oldCloudIds: Array.from(oldCloudIdsToRemove) },
            {
              errorNotification: errorNotification(formatMessage),
            }
          );
        }
      }

      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
          channelId: id,
          channelConfig: JSON.stringify(formData),
          oldCloudIdsToRemove: Array.from(oldCloudIdsToRemove),
          oldPermissionIdToRemove,
          newPermissionIdToConnect,
          newCloudIdsToConnect: Array.from(newCloudIdsToConnect),
        },
      });
      store.dispatch(CHANNEL_ACTIONS.SET_LOADING_NODE_CHANNELS(true));
    } else {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
          channelConfig: JSON.stringify(formData),
          newPermissionIdToConnect,
          newCloudIdsToConnect: Array.from(newCloudIdsToConnect),
        },
      });
      store.dispatch(CHANNEL_ACTIONS.SET_LOADING_NODE_CHANNELS(true));
    }
  };

  const isDirectEngine = channelConfig?.type === ENGINE_TYPES.DIRECT;
  const isTranscodingEngine = channelConfig?.type === ENGINE_TYPES.TRANSCODING;

  const parsedChannelConfig = {
    ...channelConfig,
    ...(isDirectEngine && {
      input: { ...channelConfig.input, type: INPUT_TYPES.inputUrl.value },
    }),
    ...(isTranscodingEngine && { audioStreamsCount: 1 }),
  };

  const isInputURLType = parsedChannelConfig?.input?.type === INPUT_URL_TYPE.value;

  const setInitialAutoIp = (cloudIp) => {
    if (!cloudIp) {
      return stundAddress;
    }

    return cloudIp;
  };

  const initialFormValues = {
    ...parsedChannelConfig,
    ...(channelConfig?.outputs
      ? {
          outputs: channelConfig.outputs.map((output) => {
            if (output?.urlDestinations) {
              return {
                ...output,
                urlDestinations: output?.urlDestinations?.map((outputDestination) => {
                  return {
                    ...outputDestination,
                    cloudIp: setInitialAutoIp(outputDestination?.cloudIp),
                  };
                }),
              };
            }

            return {
              ...output,
              cloudIp: setInitialAutoIp(output?.cloudIp),
            };
          }),
        }
      : {}),
    version: FORM_VERSION_V5,
  };

  return (
    <FormV5Context.Provider value={form}>
      <StyledCard>
        <Form onFinish={handleFinish} form={form} initialValues={initialFormValues}>
          <Row gutter={48}>
            {detailView && (
              <Col span={24}>
                <GlobalErrorBoundaries>
                  <DetailSection channelId={id} />
                </GlobalErrorBoundaries>
              </Col>
            )}
            <Col span={24}>
              <GlobalErrorBoundaries>
                <GeneralSection
                  channelConfig={parsedChannelConfig}
                  detailView={detailView}
                  editMode={editMode}
                  handleFinish={handleFinish}
                  id={id}
                />
              </GlobalErrorBoundaries>
            </Col>
          </Row>
          <Spin
            indicator={<InfoCircleOutlined />}
            tip={<FormattedMessage id="NewChannelForm.disabled" defaultMessage="Please fill general section before" />}
            spinning={!editMode}
          >
            <Row gutter={48}>
              <Col span={24}>
                <Row justify="space-around" align="middle">
                  <StyledColCenter span={24}>
                    <CaretDownOutlined />
                  </StyledColCenter>
                </Row>
              </Col>
              {isTranscodingEngine && <AudioStreamCount disabled={detailView} prefix={["audioStreamsCount"]} />}
              <Col span={24}>
                <GlobalErrorBoundaries>
                  {isDirectEngine && (
                    <InputSection
                      channelConfig={parsedChannelConfig}
                      detailView={detailView}
                      handleFinish={handleFinish}
                      id={id}
                      requestedStatusText={requestedStatusText}
                      sharedChannelList={sharedChannelList}
                      stundAddress={stundAddress}
                      playingSource={playingSource}
                      inputsStatus={inputsStatus}
                    />
                  )}
                  {isTranscodingEngine && (
                    <InputTranscodingSection
                      channelConfig={parsedChannelConfig}
                      detailView={detailView}
                      handleFinish={handleFinish}
                      id={id}
                      requestedStatusText={requestedStatusText}
                      sharedChannelList={sharedChannelList}
                      stundAddress={stundAddress}
                      playingSource={playingSource}
                      inputsStatus={inputsStatus}
                    />
                  )}
                </GlobalErrorBoundaries>
              </Col>
              <Col span={24}>
                <Row justify="space-around" align="middle">
                  <StyledColCenter span={24}>
                    <CaretDownOutlined />
                  </StyledColCenter>
                </Row>
              </Col>
              {isTranscodingEngine && (
                <>
                  <Col span={24}>
                    <GlobalCodecSection
                      isVideoFormat={!!parsedChannelConfig?.globalVideoFormat}
                      isAudioFormat={!!parsedChannelConfig?.globalAudioFormats}
                      handleFinish={handleFinish}
                      disabled={detailView}
                      form={form}
                    />
                  </Col>

                  <Col span={24}>
                    <Row justify="space-around" align="middle">
                      <StyledColCenter span={24}>
                        <CaretDownOutlined />
                      </StyledColCenter>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <GlobalErrorBoundaries>
                      <Card
                        title={
                          <FormattedMessage id="NewChannelForm.transcodedOutputs" defaultMessage="Transcoded Outputs" />
                        }
                      >
                        <EngineTranscodingSection
                          detailView={detailView}
                          handleFinish={handleFinish}
                          id={id}
                          isInput={!!channelConfig?.mainInput}
                        />
                      </Card>
                    </GlobalErrorBoundaries>
                  </Col>
                </>
              )}

              {isDirectEngine && (
                <Col span={24}>
                  <GlobalErrorBoundaries>
                    <Card title={<DirectOutputTitle incomplete={channelConfig?.outputs?.incomplete} />}>
                      <Spin
                        spinning={!isInputURLType || !isDirectInput}
                        tip={
                          !isDirectInput ? (
                            <FormattedMessage
                              id="NewChannelForm.inputNeeded"
                              defaultMessage="Please define input before add output"
                            />
                          ) : (
                            <FormattedMessage
                              id="NewChannelForm.disabledDirectOutputs"
                              defaultMessage="Disabled for non-URL input type"
                            />
                          )
                        }
                        indicator={<WarningOutlined />}
                      >
                        <EngineDirectOutputs channelId={id} detailView={detailView} handleFinish={handleFinish} />
                      </Spin>
                    </Card>
                  </GlobalErrorBoundaries>
                </Col>
              )}
            </Row>
          </Spin>
          <BackButton channelId={id} detailView={detailView} />
        </Form>
        <Drawer
          title={<FormattedMessage id="NodeDashboard.usedPorts" defaultMessage="Used ports" />}
          placement="right"
          onClose={() => store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(false))}
          open={openDrawer}
          width={750}
          forceRender
          zIndex={1001}
        >
          <NodePorts activeLink={false} />
        </Drawer>
      </StyledCard>
    </FormV5Context.Provider>
  );
}

const StyledCard = styled(Card)`
  .ant-card {
    margin-bottom: 10px;
  }

  .ant-descriptions-item-label {
    width: 300px;
  }
  .ant-descriptions-item-content {
    width: 100px;
  }
`;

const StyledColCenter = styled(Col)`
  text-align: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

NodeChannelFormV5.defaultProps = {
  detailView: false,
};

export default NodeChannelFormV5;
